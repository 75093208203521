
import { computed } from '#imports';
import { reactive, toRefs } from 'vue';

const state = reactive({
  isOpen: false,
  
  drawerOpenClasses: 'w-full sm:w-1/1 md:w-1/2',
  drawerCloseClasses: 'w-full sm:w-1/1 md:w-0',

});

export function useVideoCall () {

  function toggleDrawer () {
    state.isOpen = !state.isOpen;
  }

  const drawerClasses = computed(() => {
    return state.isOpen ? state.drawerOpenClasses : state.drawerCloseClasses;
  });

  const workspaceClasses = computed(() => {
    return state.isOpen ? 'w-full' : 'w-10/12';
  });
  
  return {
    ...toRefs(state),
    workspaceClasses,
    drawerClasses,
    toggleDrawer,
  };
};